import type { SVGProps } from 'react';
import React from 'react';

export function FaSolidCircleNotch(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 512" {...props}>
      <path
        fill="currentColor"
        d="M288 39.056v16.659c0 10.804 7.281 20.159 17.686 23.066C383.204 100.434 440 171.518 440 256c0 101.689-82.295 184-184 184c-101.689 0-184-82.295-184-184c0-84.47 56.786-155.564 134.312-177.219C216.719 75.874 224 66.517 224 55.712V39.064c0-15.709-14.834-27.153-30.046-23.234C86.603 43.482 7.394 141.206 8.003 257.332c.72 137.052 111.477 246.956 248.531 246.667C393.255 503.711 504 392.788 504 256c0-115.633-79.14-212.779-186.211-240.236C302.678 11.889 288 23.456 288 39.056z"
      />
    </svg>
  );
}

export function MdiLightbulbOn20(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M1 11h3v2H1v-2m3.9-7.5L3.5 4.9L5.6 7L7 5.6L4.9 3.5M13 1h-2v3h2V1m7 10v2h3v-2h-3M10 22c0 .6.4 1 1 1h2c.6 0 1-.4 1-1v-1h-4v1m9.1-18.5L17 5.6L18.4 7l2.1-2.1l-1.4-1.4M18 12c0 2.2-1.2 4.2-3 5.2V19c0 .6-.4 1-1 1h-4c-.6 0-1-.4-1-1v-1.8c-1.8-1-3-3-3-5.2c0-3.3 2.7-6 6-6s6 2.7 6 6m-2 0c0-2.21-1.79-4-4-4s-4 1.79-4 4s1.79 4 4 4s4-1.79 4-4Z"
      />
    </svg>
  );
}

export function FaSolidComments(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="1.13em" height="1em" viewBox="0 0 576 512" {...props}>
      <path
        fill="currentColor"
        d="M416 192c0-88.4-93.1-160-208-160S0 103.6 0 192c0 34.3 14.1 65.9 38 92c-13.4 30.2-35.5 54.2-35.8 54.5c-2.2 2.3-2.8 5.7-1.5 8.7S4.8 352 8 352c36.6 0 66.9-12.3 88.7-25c32.2 15.7 70.3 25 111.3 25c114.9 0 208-71.6 208-160zm122 220c23.9-26 38-57.7 38-92c0-66.9-53.5-124.2-129.3-148.1c.9 6.6 1.3 13.3 1.3 20.1c0 105.9-107.7 192-240 192c-10.8 0-21.3-.8-31.7-1.9C207.8 439.6 281.8 480 368 480c41 0 79.1-9.2 111.3-25c21.8 12.7 52.1 25 88.7 25c3.2 0 6.1-1.9 7.3-4.8c1.3-2.9.7-6.3-1.5-8.7c-.3-.3-22.4-24.2-35.8-54.5z"
      />
    </svg>
  );
}

export function FaSolidFeatherAlt(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 512" {...props}>
      <path
        fill="currentColor"
        d="M512 0C460.22 3.56 96.44 38.2 71.01 287.61c-3.09 26.66-4.84 53.44-5.99 80.24l178.87-178.69c6.25-6.25 16.4-6.25 22.65 0s6.25 16.38 0 22.63L7.04 471.03c-9.38 9.37-9.38 24.57 0 33.94c9.38 9.37 24.59 9.37 33.98 0l57.13-57.07c42.09-.14 84.15-2.53 125.96-7.36c53.48-5.44 97.02-26.47 132.58-56.54H255.74l146.79-48.88c11.25-14.89 21.37-30.71 30.45-47.12h-81.14l106.54-53.21C500.29 132.86 510.19 26.26 512 0z"
      />
    </svg>
  );
}

export function FaSolidHistory(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 512" {...props}>
      <path
        fill="currentColor"
        d="M504 255.531c.253 136.64-111.18 248.372-247.82 248.468c-59.015.042-113.223-20.53-155.822-54.911c-11.077-8.94-11.905-25.541-1.839-35.607l11.267-11.267c8.609-8.609 22.353-9.551 31.891-1.984C173.062 425.135 212.781 440 256 440c101.705 0 184-82.311 184-184c0-101.705-82.311-184-184-184c-48.814 0-93.149 18.969-126.068 49.932l50.754 50.754c10.08 10.08 2.941 27.314-11.313 27.314H24c-8.837 0-16-7.163-16-16V38.627c0-14.254 17.234-21.393 27.314-11.314l49.372 49.372C129.209 34.136 189.552 8 256 8c136.81 0 247.747 110.78 248 247.531zm-180.912 78.784l9.823-12.63c8.138-10.463 6.253-25.542-4.21-33.679L288 256.349V152c0-13.255-10.745-24-24-24h-16c-13.255 0-24 10.745-24 24v135.651l65.409 50.874c10.463 8.137 25.541 6.253 33.679-4.21z"
      />
    </svg>
  );
}

export function FaSolidSubway(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="0.88em" height="1em" viewBox="0 0 448 512" {...props}>
      <path
        fill="currentColor"
        d="M448 96v256c0 51.815-61.624 96-130.022 96l62.98 49.721C386.905 502.417 383.562 512 376 512H72c-7.578 0-10.892-9.594-4.957-14.279L130.022 448C61.82 448 0 403.954 0 352V96C0 42.981 64 0 128 0h192c65 0 128 42.981 128 96zM200 232V120c0-13.255-10.745-24-24-24H72c-13.255 0-24 10.745-24 24v112c0 13.255 10.745 24 24 24h104c13.255 0 24-10.745 24-24zm200 0V120c0-13.255-10.745-24-24-24H272c-13.255 0-24 10.745-24 24v112c0 13.255 10.745 24 24 24h104c13.255 0 24-10.745 24-24zm-48 56c-26.51 0-48 21.49-48 48s21.49 48 48 48s48-21.49 48-48s-21.49-48-48-48zm-256 0c-26.51 0-48 21.49-48 48s21.49 48 48 48s48-21.49 48-48s-21.49-48-48-48z"
      />
    </svg>
  );
}

export function FaSolidUserFriends(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="1.25em" height="1em" viewBox="0 0 640 512" {...props}>
      <path
        fill="currentColor"
        d="M192 256c61.9 0 112-50.1 112-112S253.9 32 192 32S80 82.1 80 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C51.6 288 0 339.6 0 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zM480 256c53 0 96-43 96-96s-43-96-96-96s-96 43-96 96s43 96 96 96zm48 32h-3.8c-13.9 4.8-28.6 8-44.2 8s-30.3-3.2-44.2-8H432c-20.4 0-39.2 5.9-55.7 15.4c24.4 26.3 39.7 61.2 39.7 99.8v38.4c0 2.2-.5 4.3-.6 6.4H592c26.5 0 48-21.5 48-48c0-61.9-50.1-112-112-112z"
      />
    </svg>
  );
}

export function FaSolidCircle(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 512" {...props}>
      <path
        fill="currentColor"
        d="M256 8C119 8 8 119 8 256s111 248 248 248s248-111 248-248S393 8 256 8z"
      />
    </svg>
  );
}

export function FaSolidDotCircle(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 512" {...props}>
      <path
        fill="currentColor"
        d="M256 56c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m0-48C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 168c-44.183 0-80 35.817-80 80s35.817 80 80 80 80-35.817 80-80-35.817-80-80-80z"
      />
    </svg>
  );
}

export function FaSolidTv(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="1.25em" height="1em" viewBox="0 0 640 512" {...props}>
      <path
        fill="currentColor"
        d="M592 0H48A48 48 0 0 0 0 48v320a48 48 0 0 0 48 48h240v32H112a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16H352v-32h240a48 48 0 0 0 48-48V48a48 48 0 0 0-48-48zm-16 352H64V64h512z"
      />
    </svg>
  );
}

export function IconParkOutlineTencentQq(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g fill="currentColor">
        <path d="M23.794 44.518c-3.659 0-7.017-1.225-9.179-3.053c-1.098.328-2.502.855-3.389 1.51c-.759.56-.664 1.13-.527 1.361c.6 1.013 10.296.647 13.095.332v-.15Zm0 0c3.659 0 7.016-1.225 9.179-3.053c1.097.328 2.502.855 3.389 1.51c.758.56.663 1.13.526 1.361c-.6 1.013-10.295.647-13.094.332v-.15Z" />
        <path d="M23.814 22.591c6.042-.04 10.884-1.21 12.525-1.658c.39-.107.6-.3.6-.3c.001-.055.025-.983.025-1.462c0-8.066-3.808-16.17-13.172-16.171c-9.364 0-13.171 8.105-13.171 16.171c0 .48.023 1.407.025 1.462c0 0 .17.18.481.267c1.515.421 6.448 1.65 12.643 1.691h.044Zm16.418 6.72c-.375-1.206-.886-2.61-1.404-3.96c0 0-.297-.037-.448.006c-4.645 1.35-10.275 2.21-14.566 2.158h-.044c-4.267.05-9.858-.8-14.488-2.136c-.177-.05-.526-.029-.526-.029c-.518 1.35-1.029 2.756-1.403 3.96c-1.786 5.748-1.208 8.126-.767 8.18c.945.114 3.68-4.327 3.68-4.327c0 4.513 4.073 11.441 13.403 11.505h.247c9.329-.064 13.403-6.992 13.403-11.505c0 0 2.734 4.44 3.68 4.327c.44-.054 1.019-2.432-.767-8.18Z" />
        <path d="M20.46 14.916c-1.27.057-2.355-1.39-2.423-3.23c-.07-1.84.904-3.378 2.174-3.436c1.27-.057 2.354 1.39 2.423 3.23c.07 1.84-.904 3.38-2.174 3.436Zm9.088-3.23c-.068 1.84-1.153 3.287-2.424 3.23c-1.27-.057-2.242-1.595-2.173-3.436c.069-1.84 1.154-3.286 2.423-3.23c1.27.058 2.243 1.596 2.174 3.437Zm2.257 5.828c-.34-.751-3.758-1.59-7.99-1.59h-.046c-4.232 0-7.65.839-7.99 1.59a.253.253 0 0 0-.025.108c0 .052.017.102.047.145c.286.416 4.082 2.477 7.968 2.477h.046c3.886 0 7.682-2.06 7.968-2.477a.256.256 0 0 0 .047-.146a.25.25 0 0 0-.025-.106" />
        <path d="M22.022 11.714c.058.727-.34 1.373-.89 1.443c-.549.07-1.04-.461-1.1-1.188c-.057-.727.341-1.373.89-1.443c.55-.071 1.042.461 1.1 1.188Zm3.49.243c.112-.201.877-1.259 2.46-.874c.415.102.608.25.648.309c.06.086.077.21.016.375c-.12.329-.369.32-.506.256c-.09-.042-1.192-.777-2.208.32c-.07.075-.195.1-.313.012c-.119-.09-.167-.272-.097-.398ZM23.814 27.25h-.045c-2.918.035-6.455-.351-9.882-1.027c-.293 1.699-.47 3.834-.318 6.38c.384 6.433 4.205 10.478 10.104 10.536h.24c5.898-.058 9.718-4.103 10.103-10.537c.152-2.546-.025-4.68-.319-6.379c-3.426.676-6.965 1.063-9.883 1.027" />
        <path d="M15.504 26.712v6.332s2.9.585 5.807.18v-5.841a53.39 53.39 0 0 1-5.807-.671Z" />
        <path d="M36.938 20.634s-5.642 1.78-13.124 1.831h-.044c-7.47-.05-13.105-1.825-13.124-1.831l-1.89 4.716c4.726 1.425 10.584 2.343 15.014 2.29h.044c4.43.053 10.287-.865 15.014-2.29l-1.89-4.716Z" />
      </g>
    </svg>
  );
}

export function MdiFlask(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 22 22" {...props}>
      <path
        fill="currentColor"
        d="M6 22a3 3 0 0 1-3-3c0-.6.18-1.16.5-1.63L9 7.81V6a1 1 0 0 1-1-1V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1a1 1 0 0 1-1 1v1.81l5.5 9.56c.32.47.5 1.03.5 1.63a3 3 0 0 1-3 3H6m-1-3a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1c0-.21-.07-.41-.18-.57l-2.29-3.96L14 17l-5.07-5.07l-3.75 6.5c-.11.16-.18.36-.18.57m8-9a1 1 0 0 0-1 1a1 1 0 0 0 1 1a1 1 0 0 0 1-1a1 1 0 0 0-1-1Z"
      />
    </svg>
  );
}

export function IcTwotoneSignpost(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 22 22" {...props}>
      <path
        fill="currentColor"
        d="M6 6h11.17l1 1l-1 1H6V6zm12 10H6.83l-1-1l1-1H18v2z"
        opacity=".3"
      />
      <path
        fill="currentColor"
        d="M13 10h5l3-3l-3-3h-5V2h-2v2H4v6h7v2H6l-3 3l3 3h5v4h2v-4h7v-6h-7v-2zM6 6h11.17l1 1l-1 1H6V6zm12 10H6.83l-1-1l1-1H18v2z"
      />
    </svg>
  );
}

export function IonBook(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 512" {...props}>
      <path
        fill="currentColor"
        d="M202.24 74C166.11 56.75 115.61 48.3 48 48a31.36 31.36 0 0 0-17.92 5.33A32 32 0 0 0 16 79.9V366c0 19.34 13.76 33.93 32 33.93c71.07 0 142.36 6.64 185.06 47a4.11 4.11 0 0 0 6.94-3V106.82a15.89 15.89 0 0 0-5.46-12A143 143 0 0 0 202.24 74Zm279.68-20.7A31.33 31.33 0 0 0 464 48c-67.61.3-118.11 8.71-154.24 26a143.31 143.31 0 0 0-32.31 20.78a15.93 15.93 0 0 0-5.45 12v337.13a3.93 3.93 0 0 0 6.68 2.81c25.67-25.5 70.72-46.82 185.36-46.81a32 32 0 0 0 32-32v-288a32 32 0 0 0-14.12-26.61Z"
      />
    </svg>
  );
}

export function RiNeteaseCloudMusicLine(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 22 22" {...props}>
      <path
        fill="currentColor"
        d="M10.421 11.375c-.294 1.028.012 2.064.784 2.653c1.061.81 2.565.3 2.874-.995c.08-.337.103-.722.027-1.056c-.23-1.001-.52-1.988-.792-2.996c-1.33.154-2.543 1.172-2.893 2.394zm5.548-.287c.273 1.012.285 2.017-.127 3c-1.128 2.69-4.721 3.14-6.573.826c-1.302-1.627-1.28-3.961.06-5.734c.78-1.032 1.804-1.707 3.048-2.054l.379-.104c-.084-.415-.188-.816-.243-1.224c-.176-1.317.512-2.503 1.744-3.04c1.226-.535 2.708-.216 3.53.76c.406.479.395 1.08-.025 1.464c-.412.377-.996.346-1.435-.09c-.247-.246-.51-.44-.877-.436c-.525.006-.987.418-.945.937c.037.468.173.93.3 1.386c.022.078.216.135.338.153c1.334.197 2.504.731 3.472 1.676c2.558 2.493 2.861 6.531.672 9.44c-1.529 2.032-3.61 3.168-6.127 3.409c-4.621.44-8.664-2.53-9.7-7.058c-.945-4.144 1.38-8.568 5.335-10.149c.586-.234 1.143-.031 1.371.498c.232.537-.019 1.086-.61 1.35c-2.368 1.06-3.817 2.855-4.215 5.424c-.533 3.433 1.656 6.776 5 7.72c2.723.77 5.658-.166 7.308-2.33c1.586-2.08 1.4-5.099-.427-6.873a3.979 3.979 0 0 0-1.823-1.013c.198.716.389 1.388.57 2.062z"
      />
    </svg>
  );
}

export function IcBaselineLiveTv(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 23 23" {...props}>
      <path
        fill="currentColor"
        d="M21 6h-7.59l3.29-3.29L16 2l-4 4l-4-4l-.71.71L10.59 6H3a2 2 0 0 0-2 2v12c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V8a2 2 0 0 0-2-2zm0 14H3V8h18v12zM9 10v8l7-4z"
      />
    </svg>
  );
}

export function JamRssFeed(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="1em"
      height="1em"
      viewBox="-4 -4 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M1.996 15.97a1.996 1.996 0 1 1 0-3.992a1.996 1.996 0 0 1 0 3.992zM1.12 7.977a.998.998 0 0 1-.247-1.98a8.103 8.103 0 0 1 9.108 8.04v.935a.998.998 0 1 1-1.996 0v-.934a6.108 6.108 0 0 0-6.865-6.06zM0 1.065A.998.998 0 0 1 .93.002C8.717-.517 15.448 5.374 15.967 13.16c.042.626.042 1.254 0 1.88a.998.998 0 1 1-1.992-.133c.036-.538.036-1.077 0-1.614C13.53 6.607 7.75 1.548 1.065 1.994A.998.998 0 0 1 0 1.064z"
      />
    </svg>
  );
}

export function AkarIconsMention(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" stroke="currentColor" strokeWidth="2">
        <circle cx="12" cy="12" r="4" />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12s4.477 10 10 10c2.252 0 4.33-.744 6.001-2"
        />
        <path strokeLinecap="round" d="M16 8v4c0 1 .6 3 3 3s3-2 3-3" />
      </g>
    </svg>
  );
}

export function RMixPlanet(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      fill="currentColor"
      {...props}
    >
      <path d="M3.91762 8.0366C3.32984 9.23328 3 10.5792 3 11.9999C3 16.9704 7.02944 20.9999 12 20.9999C13.4216 20.9999 14.7684 20.6696 15.9657 20.081C16.8385 20.4544 17.6848 20.6991 18.4564 20.762C19.3582 20.8356 20.3 20.6665 20.9818 19.9847C21.7339 19.2325 21.8625 18.1689 21.7279 17.1727C21.6052 16.2638 21.2481 15.2537 20.726 14.2114C20.9051 13.503 21 12.7619 21 11.9999C21 7.02929 16.9706 2.99986 12 2.99986C11.2389 2.99986 10.4987 3.09454 9.79103 3.27318C8.7474 2.7498 7.73605 2.39172 6.8261 2.26834C5.82897 2.13315 4.76406 2.26128 4.01121 3.01413C3.3287 3.69664 3.16001 4.63956 3.2341 5.54233C3.29752 6.315 3.54313 7.16247 3.91762 8.0366ZM5.3224 5.96574C5.2734 5.75321 5.24204 5.55715 5.2274 5.37873C5.17928 4.79243 5.31727 4.53649 5.42543 4.42834C5.54452 4.30925 5.84797 4.15403 6.55739 4.25021C6.75482 4.27698 6.96951 4.32189 7.2 4.38547C6.50364 4.82527 5.87203 5.35784 5.3224 5.96574ZM19.6124 16.803C19.6751 17.0316 19.7195 17.2445 19.7459 17.4404C19.8416 18.1485 19.6865 18.4515 19.5676 18.5705C19.4595 18.6785 19.204 18.8163 18.6189 18.7686C18.4419 18.7542 18.2475 18.7233 18.0368 18.675C18.6427 18.1268 19.1736 17.4971 19.6124 16.803ZM15.8812 17.8264C14.2046 16.9482 12.2571 15.5026 10.3752 13.6207C8.4954 11.741 7.05092 9.79561 6.17241 8.12028C7.06357 6.78465 8.40124 5.77312 9.96933 5.29866C10.6108 5.10457 11.2923 4.99986 12 4.99986C15.866 4.99986 19 8.13386 19 11.9999C19 12.7083 18.8951 13.3904 18.7006 14.0325C18.2261 15.5991 17.2155 16.9356 15.8812 17.8264ZM13.6052 18.8152C13.0901 18.9359 12.5528 18.9999 12 18.9999C8.13401 18.9999 5 15.8658 5 11.9999C5 11.4476 5.06377 10.9109 5.18429 10.3963C6.14883 11.913 7.43475 13.5087 8.96096 15.0349C10.489 16.563 12.0868 17.8502 13.6052 18.8152Z" />
    </svg>
  );
}
